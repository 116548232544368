import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUserInformation } from "redux/types/userInformation";

// TODO: move to auth slice
const initialState: IUserInformation = {
  id: "",
  email: "",
  userName: "",
  firstName: "",
  lastName: "",
  role: "",
};

export const userInformationSlice = createSlice({
  name: "userInformation",
  initialState,
  reducers: {
    userInformation: (state, action: PayloadAction<IUserInformation>) => {
      state.id = action.payload.id;
      state.email = action.payload.email;
      state.userName = action.payload.userName;
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.role = action.payload.role || "";
    },
  },
});

export const { userInformation } = userInformationSlice.actions;
export default userInformationSlice.reducer;
