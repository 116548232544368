import { Controller } from "react-hook-form";
import { TextField } from "@mui/material";
import { createRef, useEffect, useState } from "react";
import { darkGreen, lightGreenColor, middleGreen } from "mui/customColors";

interface IAppTextInputProps {
  control: any;
  name: string;
  label?: string;
  autoComplete?: string;
  onClick?: Function;
  isAutoFocus?: boolean;
  isFirstLetterUppercase?: boolean;
}

export default function AppTextInput(props: IAppTextInputProps) {
  const {
    control,
    name,
    label,
    autoComplete,
    onClick,
    isAutoFocus,
    isFirstLetterUppercase,
  } = props;
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const textFieldRef = createRef<HTMLElement>();

  useEffect(() => {
    if (!isFocused && isAutoFocus && textFieldRef) {
      textFieldRef?.current?.focus();
      setIsFocused(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textFieldRef]);

  return (
    <Controller
      name={name}
      defaultValue={""}
      control={control}
      render={({ field, fieldState }) => (
        <TextField
          inputRef={textFieldRef}
          fullWidth
          {...field}
          label={label}
          autoComplete={autoComplete}
          variant="outlined"
          margin="dense"
          error={!!fieldState.error}
          helperText={fieldState.error ? fieldState.error.message : ""}
          inputProps={{
            onClick: () => onClick && onClick(),
            style: {
              textTransform: isFirstLetterUppercase ? "capitalize" : "none",
              backgroundColor: lightGreenColor,
              border: darkGreen,
            },
          }}
        />
      )}
    />
  );
}
