import { Link, Link as RouterLink } from "react-router-dom";
import { styled } from "@mui/system";
import { grey, red } from "@mui/material/colors";
import { ReactNode } from "react";

interface IAppLinkProps {
  to: string;
  children: ReactNode | string | null;
}

const Mylink = styled(
  Link,
  {}
)(({ theme }) => ({
  color: theme.palette.mode === "light" ? grey[800] : grey[100],
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
}));

export default function AppLinkBrightness(props: IAppLinkProps) {
  const { to, children } = props;
  return (
    <Mylink
      sx={{
        textDecoration: "none",
      }}
      to={to}
    >
      {children}
    </Mylink>
  );
}
