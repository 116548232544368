import { Alert } from "@mui/material";
import { styled } from "@mui/system";

const AppFormSuccesBox = styled(
  Alert,
  {}
)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  marginTop: theme.spacing(2),
  width: "100%",
  justifyContent: "center",
}));

AppFormSuccesBox.defaultProps = {
  severity: "success",
};

export default AppFormSuccesBox;
