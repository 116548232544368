import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// TODO: move to calendar slice
export interface ICalendarDate {
  calendarDate: string;
  calendarHour?: string;
}

const initialState: ICalendarDate = {
  calendarDate: "",
  calendarHour: "",
};

export const calendarHandleDateSlice = createSlice({
  name: "calendarHandleDate",
  initialState,
  reducers: {
    setCalendarHandleDate: (state, action: PayloadAction<ICalendarDate>) => {
      state.calendarDate = action.payload.calendarDate;
      state.calendarHour = action.payload.calendarHour;
    },
  },
});

export const { setCalendarHandleDate } = calendarHandleDateSlice.actions;
export default calendarHandleDateSlice.reducer;
