import { ReactNode } from "react";
import { Grid } from "@mui/material";
import AppCenteredCardBorder from "./AppCenteredCardBorder";

interface IAppCenteredCardProps {
  children: ReactNode;
}

export default function AppCenteredCard(props: IAppCenteredCardProps) {
  const { children } = props;
  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      mb={"100px"}
    >
      <AppCenteredCardBorder> {children}</AppCenteredCardBorder>
    </Grid>
  );
}
