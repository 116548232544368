import { ReactNode, useCallback, useEffect, useState } from "react";
import {
  clearLocalStorage,
  getValueFromLocalStorage,
} from "utils/localStorage";
import jwt_decode from "jwt-decode";
import authService from "services/auth.service";
import { IRefreshTokenResponse } from "redux/types/auth";
import localStorageService from "services/localStorage/localStorage.service";
import { userTokenExpire } from "utils/userTokenConsts";

interface IAppTokenValidateWrapper {
  children: ReactNode;
}

export default function AppTokenValidateWrapper(
  props: IAppTokenValidateWrapper
) {
  const { children } = props;
  const [isVerifyProccess, setIsVerifyProccess] = useState<boolean>(true);
  const refreshTokenExpire = getValueFromLocalStorage("refreshTokenExpire");
  const refreshToken = getValueFromLocalStorage("refreshToken") || "";
  const userToken = getValueFromLocalStorage("userToken") || "";

  const checkIsTokenExpire = useCallback((expireTime: number) => {
    return expireTime * 1000 < Date.now();
  }, []);

  const clearLocalStorageAndReload = useCallback(() => {
    clearLocalStorage();
    window.location.reload();
  }, []);

  const setNewTokenToLocalStorage = useCallback(
    (newToken: IRefreshTokenResponse) => {
      localStorageService.setAuthDataToLocalStorage({
        userToken: newToken.token,
        refreshToken: newToken.refreshToken,
        refreshTokenExpire: newToken.refreshTokenExpire.toString(),
      });
      setIsVerifyProccess(false);
    },
    []
  );

  const getNewToken = useCallback(
    (refreshToken: string) => {
      authService
        .refreshToken(refreshToken)
        .then((response) => {
          if (response.noToken) {
            clearLocalStorageAndReload();
          } else {
            setNewTokenToLocalStorage(response);
          }
        })
        .catch(() => setIsVerifyProccess(false));
    },
    [clearLocalStorageAndReload, setNewTokenToLocalStorage]
  );

  useEffect(() => {
    !!!userToken && clearLocalStorageAndReload();

    const decoded: { [key: string]: string } = jwt_decode(userToken);
    const tokenExpire = decoded[userTokenExpire];
    if (
      !!refreshTokenExpire &&
      checkIsTokenExpire(Number(tokenExpire)) &&
      checkIsTokenExpire(Number(refreshTokenExpire))
    ) {
      clearLocalStorageAndReload();
    } else if (
      !!refreshTokenExpire &&
      checkIsTokenExpire(Number(tokenExpire)) &&
      !checkIsTokenExpire(Number(refreshTokenExpire))
    ) {
      getNewToken(refreshToken);
    } else {
      setIsVerifyProccess(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkIsTokenExpire, clearLocalStorageAndReload, getNewToken]);

  return <>{isVerifyProccess ? null : children}</>;
}
