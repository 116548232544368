import { Alert } from "@mui/material";
import { styled } from "@mui/system";

const AppFormInfoBox = styled(
  Alert,
  {}
)(({ theme }) => ({ marginBottom: theme.spacing(2) }));

AppFormInfoBox.defaultProps = {
  severity: "warning",
};

export default AppFormInfoBox;
