const orthodonticGreen = "#13C97F";
const stomatologicBlue = "#09B6D6";
const darkGreen = "#40C07B";
const middleGreen = "#DDFFF1";
const closedInstitutionColor = "#888";
const holidayEventsColor = "#f6bf26";
const zoomIconColor = "#808080";
const zoomHoverIconColor = "#000";
const patientLegendColor = "#AAA";
const lightGreenColor = "#F4FFFA";
const cardHoverColor = "#CCC";
const pureWhite = "#fff";

export {
  orthodonticGreen,
  stomatologicBlue,
  darkGreen,
  middleGreen,
  closedInstitutionColor,
  holidayEventsColor,
  zoomIconColor,
  zoomHoverIconColor,
  patientLegendColor,
  lightGreenColor,
  cardHoverColor,
  pureWhite,
};
