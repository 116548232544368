import { useState } from "react";
import { Controller } from "react-hook-form";
import {
  FormHelperText,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { red } from "@mui/material/colors";

interface IAppPasswordInputProps {
  control: any;
  name: string;
  label: string;
}

export default function AppPasswordInput(props: IAppPasswordInputProps) {
  const { control, name } = props;
  const [values, setValues] = useState({
    password: "",
    showPassword: false,
  });

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  return (
    <Controller
      name={name}
      defaultValue={""}
      control={control}
      render={({ field, fieldState }) => (
        <>
          <TextField
            {...field}
            label={""}
            autoComplete="off"
            type={values.showPassword ? "text" : "password"}
            margin="dense"
            error={!!fieldState.error}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <FormHelperText
            style={{
              color: red[500],
            }}
          >
            {fieldState && (fieldState.error ? fieldState.error.message : "")}
          </FormHelperText>
        </>
      )}
    />
  );
}
