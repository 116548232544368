import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// TODO: move to calendar slice
export type CalendarTypes = "day" | "week" | "month";

const initialState: { calendarType: CalendarTypes } = {
  calendarType: "day",
};

export const calendarSelectedSlice = createSlice({
  name: "calendarSelected",
  initialState,
  reducers: {
    setCalendarSelected: (state, action: PayloadAction<CalendarTypes>) => {
      state.calendarType = action.payload;
    },
  },
});

export const { setCalendarSelected } = calendarSelectedSlice.actions;
export default calendarSelectedSlice.reducer;
