import { Box, CircularProgress } from "@mui/material";

export default function AppLoader() {
  return (
    <Box sx={appLoaderStyle}>
      <CircularProgress
        sx={{ margin: "auto" }}
        color="primary"
        disableShrink={true}
      />
    </Box>
  );
}

const appLoaderStyle = {
  display: "flex",
  height: "100vh",
};
