import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: { clear: boolean } = {
  clear: false,
};

// TODO: check other slice
export const clearSearchbarSlice = createSlice({
  name: "clearSearchbar",
  initialState,
  reducers: {
    setClearSearchbar: (state, action: PayloadAction<boolean>) => {
      state.clear = action.payload;
    },
  },
});

export const { setClearSearchbar } = clearSearchbarSlice.actions;
export default clearSearchbarSlice.reducer;
