import { lazy, Suspense, useMemo } from "react";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import { GuestRoute, PrivateRoute, RoleRoute } from "./utils/http";
import { Admin, Doctor, SuperAdmin, User } from "./redux/types/auth";
import SnackbarHandler from "components/common/SnackbarHandler";
import AppTokenValidateWrapper from "components/layouts/AppTokenValidateWrapper";
import AppLoader from "components/layouts/AppLoader";
import ConfirmResetPasswordPage from "modules/auth/ConfirmResetPasswordPage";

export default function AppRouter() {
  const Login = useMemo(
    () => lazy(() => import("./modules/auth/LoginPage")),
    []
  );
  const PasswordReset = useMemo(
    () => lazy(() => import("./modules/auth/ResetPasswordPage")),
    []
  );
  const Calendar = useMemo(
    () => lazy(() => import("./modules/dashboard/CalendarPage")),
    []
  );
  const UserInformation = useMemo(
    () => lazy(() => import("./modules/dashboard/UserDetailsPage")),
    []
  );
  const UnavailablePage = lazy(() => import("./modules/utils/UnavailablePage"));
  const ConfirmAccountPage = useMemo(
    () => lazy(() => import("./modules/auth/ConfirmAccountPage")),
    []
  );
  const AddNoteMobile = useMemo(
    () => lazy(() => import("./modals/addNote/AddNoteMobile")),
    []
  );
  const EditNoteMobile = useMemo(
    () => lazy(() => import("./modals/editNote/EditNoteMobile")),
    []
  );
  const AddVisitMobile = useMemo(
    () => lazy(() => import("./modals/addVisit/AddVisitMobile")),
    []
  );
  const EditVisitMobile = useMemo(
    () => lazy(() => import("./modals/editVisit/EditVisitMobile")),
    []
  );
  const NextAvailableMobile = useMemo(
    () => lazy(() => import("./modals/nextAvailable/NextAvailableMobile")),
    []
  );
  const AddPatientMobile = useMemo(
    () => lazy(() => import("./modals/addPatient/AddPatientMobile")),
    []
  );
  const VacationPlannerMobile = useMemo(
    () => lazy(() => import("./modals/vacationPlanner/VacationPlannerMobile")),
    []
  );
  const VacationFormMobile = useMemo(
    () => lazy(() => import("./modals/vacationPlanner/VacationFormMobile")),
    []
  );
  const VacationEditFormMobile = useMemo(
    () => lazy(() => import("./modals/editVacation/VacationEditFormMobile")),
    []
  );
  const SendMessageMobile = useMemo(
    () => lazy(() => import("./modals/sendMessage/SendMessageMobile")),
    []
  );
  const SendMessageReportsMobile = useMemo(
    () =>
      lazy(
        () => import("./modals/sendMessageReports/SendMessageReportsMobile")
      ),
    []
  );
  return (
    <BrowserRouter>
      <Suspense fallback={<AppLoader />}>
        <Routes>
          <Route
            path="/"
            element={
              <PrivateRoute>
                <Login />
              </PrivateRoute>
            }
          />
          <Route
            path="/password-reset"
            element={
              <PrivateRoute>
                <PasswordReset />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard/calendar"
            element={
              <RoleRoute role={[Doctor, Admin, SuperAdmin, User]}>
                <AppTokenValidateWrapper>
                  <Calendar />
                </AppTokenValidateWrapper>
              </RoleRoute>
            }
          />
          <Route
            path="/dashboard/user-information"
            element={
              <RoleRoute role={[Doctor, Admin, SuperAdmin, User]}>
                <AppTokenValidateWrapper>
                  <UserInformation />
                </AppTokenValidateWrapper>
              </RoleRoute>
            }
          />
          <Route
            path="/confirm-account/:userId"
            element={
              <GuestRoute>
                <ConfirmAccountPage />
              </GuestRoute>
            }
          />
          <Route
            path="/confirm-reset-password/:userId"
            element={
              <GuestRoute>
                <ConfirmResetPasswordPage />
              </GuestRoute>
            }
          />
          <Route
            path="/dashboard/add-note"
            element={
              <RoleRoute role={[Doctor, Admin, SuperAdmin, User]}>
                <AppTokenValidateWrapper>
                  <AddNoteMobile />
                </AppTokenValidateWrapper>
              </RoleRoute>
            }
          />
          <Route
            path="/dashboard/edit-note"
            element={
              <RoleRoute role={[Doctor, Admin, SuperAdmin, User]}>
                <AppTokenValidateWrapper>
                  <EditNoteMobile />
                </AppTokenValidateWrapper>
              </RoleRoute>
            }
          />
          <Route
            path="/dashboard/add-visit"
            element={
              <RoleRoute role={[Doctor, Admin, SuperAdmin, User]}>
                <AppTokenValidateWrapper>
                  <AddVisitMobile />
                </AppTokenValidateWrapper>
              </RoleRoute>
            }
          />
          <Route
            path="/dashboard/edit-visit"
            element={
              <RoleRoute role={[Doctor, Admin, SuperAdmin, User]}>
                <AppTokenValidateWrapper>
                  <EditVisitMobile />
                </AppTokenValidateWrapper>
              </RoleRoute>
            }
          />
          <Route
            path="/dashboard/next-available"
            element={
              <RoleRoute role={[Doctor, Admin, SuperAdmin, User]}>
                <AppTokenValidateWrapper>
                  <NextAvailableMobile />
                </AppTokenValidateWrapper>
              </RoleRoute>
            }
          />
          <Route
            path="/dashboard/add-patient"
            element={
              <RoleRoute role={[Doctor, Admin, SuperAdmin, User]}>
                <AppTokenValidateWrapper>
                  <AddPatientMobile />
                </AppTokenValidateWrapper>
              </RoleRoute>
            }
          />
          <Route
            path="/dashboard/vacation-planner"
            element={
              <RoleRoute role={[Doctor, Admin, SuperAdmin, User]}>
                <AppTokenValidateWrapper>
                  <VacationPlannerMobile />
                </AppTokenValidateWrapper>
              </RoleRoute>
            }
          />
          <Route
            path="/dashboard/vacation-planner/add"
            element={
              <RoleRoute role={[Doctor, Admin, SuperAdmin, User]}>
                <AppTokenValidateWrapper>
                  <VacationFormMobile />
                </AppTokenValidateWrapper>
              </RoleRoute>
            }
          />
          <Route
            path="/dashboard/vacation-planner/edit"
            element={
              <RoleRoute role={[Doctor, Admin, SuperAdmin, User]}>
                <AppTokenValidateWrapper>
                  <VacationEditFormMobile />
                </AppTokenValidateWrapper>
              </RoleRoute>
            }
          />
          <Route
            path="/dashboard/send-message"
            element={
              <RoleRoute role={[Doctor, Admin, SuperAdmin, User]}>
                <AppTokenValidateWrapper>
                  <SendMessageMobile />
                </AppTokenValidateWrapper>
              </RoleRoute>
            }
          />
          <Route
            path="/dashboard/send-report-messages"
            element={
              <RoleRoute role={[Doctor, Admin, SuperAdmin, User]}>
                <AppTokenValidateWrapper>
                  <SendMessageReportsMobile />
                </AppTokenValidateWrapper>
              </RoleRoute>
            }
          />
          <Route path="*" element={<UnavailablePage />} />
        </Routes>
        <SnackbarHandler />
      </Suspense>
    </BrowserRouter>
  );
}
