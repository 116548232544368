import { ReactNode } from "react";
import { FormControl } from "@mui/material";

interface IAppForm {
  children: ReactNode;
}

export default function AppForm(props: IAppForm) {
  const { children } = props;
  return (
    <form>
      <FormControl fullWidth>{children}</FormControl>
    </form>
  );
}
