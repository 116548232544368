export interface IAuthentication {
  email: string;
  password: string;
  token: string | null;
}

export interface IPassword {
  email: string;
}

export interface IChangePassword {
  lastPassword: string;
  newPasword: string;
}

export interface IUserData {
  firstName: string;
  lastName: string;
}

export interface IAuthenticationState {
  userToken: String | null;
  isLoggedIn: Boolean;
  refreshToken: String | null;
}

export interface IConfirmAccount {
  userId: string;
  token: string;
  password: string;
}

export interface IConfirmResetPassword {
  userId: string;
  token: string;
  password: string;
  codeFromReCaptcha: string;
}

export type UserRoleType =
  | "ROLE_DOCTOR"
  | "ROLE_APPLICATION_ADMIN"
  | "ROLE_SECRETARY"
  | "ROLE_STUDENT"
  | "ROLE_SUPER_ADMIN"
  | "ROLE_USER";
export const Doctor: UserRoleType = "ROLE_DOCTOR";
export const Admin: UserRoleType = "ROLE_APPLICATION_ADMIN";
export const Secretary: UserRoleType = "ROLE_SECRETARY";
export const Student: UserRoleType = "ROLE_STUDENT";
export const SuperAdmin: UserRoleType = "ROLE_SUPER_ADMIN";
export const User: UserRoleType = "ROLE_USER";

export interface ILoginResponse {
  validationErrors: string;
  hasErrors: boolean;
  errors: string[];
  token: string;
  refreshToken: string;
  refreshTokenExpire: number;
}

export interface IRefreshTokenResponse {
  token: string;
  refreshToken: string;
  refreshTokenExpire: number;
  noToken: boolean;
}