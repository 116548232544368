import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import SnackbarDispatch from "utils/dispatch/SnackbarDispatch";
import errorSnackbar from "utils/errorSnackbar";
import { newPasswordValidationSchema } from "yup/newPasswordValidation";
import passwordChangeService from "services/passwordChange.service";

type ResetPasswordFormData = {
  password: string;
  repeatPassword: string;
};

export default function useConfirmResetPasswordForm() {
  let [searchParams] = useSearchParams();
  let { userId } = useParams();
  const navigate = useNavigate();
  const [changePending, setChangePending] = useState<boolean>(false);
  const { displaySuccessText } = SnackbarDispatch();
  const { handleSubmit, control, reset } = useForm<ResetPasswordFormData>({
    mode: "onChange",
    resolver: yupResolver(newPasswordValidationSchema),
  });

  const onSubmit = handleSubmit((data) => {
    setChangePending(true);
    passwordChangeService
      .confirmResetPassword({
        userId: String(userId),
        token: String(searchParams.get("token")),
        password: data.password,
        codeFromReCaptcha: ""
      })
      .then(() => {
        displaySuccessText("Zmieniono hasło");
      })
      .catch((error) => {
        if (
          error.response.status === 400 &&
          error.response.data[0] === "Błędny token"
        ) {
          errorSnackbar("Link do resetowania hasła wygasł");
        } else {
          errorSnackbar("Wystąpił nieznany błąd, spróbuj ponownie lub odwiedź stronę ortobajt.pl");
        }
      })
      .finally(() => {
        reset();
        setChangePending(false);
        navigate("/");
      });
  });

  return { control, onSubmit, changePending };
}
