import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IEventInfo } from "redux/types/eventInfo";

// TODO: move to calendar slice
const initialState: IEventInfo = {
  databaseId: "",
  id: "",
  title: "",
  timeText: "",
  doctorName: "",
  doctorSurname: "",
  timeStart: "",
  timeEnd: "",
};

export const eventInfoSlice = createSlice({
  name: "eventInfo",
  initialState,
  reducers: {
    eventInfoData: (state, action: PayloadAction<IEventInfo>) => {
      state.databaseId = action.payload.databaseId;
      state.id = action.payload.id;
      state.title = action.payload.title;
      state.timeText = action.payload.timeText;
      state.doctorName = action.payload.doctorName;
      state.doctorSurname = action.payload.doctorSurname;
      state.timeStart = action.payload.timeStart;
      state.timeEnd = action.payload.timeEnd;
    },
  },
});

export const { eventInfoData } = eventInfoSlice.actions;
export default eventInfoSlice.reducer;
