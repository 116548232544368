import handleErrorMessage from "utils/handleErrorMessage";
import { IChangePassword, IConfirmResetPassword } from "../redux/types/auth";
import axiosInstance from "../utils/axios";

class PasswordChangeService {
  getNewPassword(Password: IChangePassword) {
    return axiosInstance
      .post("/auth/change-password", {
        currentPassword: Password.lastPassword,
        newPassword: Password.newPasword,
      })
      .then((response) => {
        return Promise.resolve<any>(response.data);
      });
  }

  confirmResetPassword(resetPasswordData: IConfirmResetPassword) {
    return axiosInstance
      .post("/auth/confirm-reset-password", {
        userId: resetPasswordData.userId,
        token: resetPasswordData.token,
        password: resetPasswordData.password,
        codeFromReCaptcha: resetPasswordData.codeFromReCaptcha
      })
      .then((response) => {
        return response;
      })
      .catch((e) => {
        handleErrorMessage(e);
        return Promise.reject(e);
      });
  }

}

export default new PasswordChangeService();
