import useUserIdentity from "hooks/use-user-identity";
import { Navigate } from "react-router-dom";
import useUserRoleChecker from "../hooks/use-user-role-checker";
import { useEffect } from "react";
import localStorageService from "services/localStorage/localStorage.service";

export function extractErrorMessage(error: any) {
  if (!error || !error.response) {
    return "Error";
  }

  if (error.response.status === 500) {
    return "500";
  }
  if (error.response.status === 401 || error.response.status === 403) {
    return "401 | 403";
  }
  if (error.response.status === 404) {
    return "404";
  }

  const message =
    (error.response && error.response.data) ||
    error.message ||
    error.toString();
  return message;
}

export function RoleRoute({ children, role }: any) {
  let hasRole = useUserRoleChecker(role);
  if (!hasRole) {
    return <Navigate to="/" replace />;
  }

  return children;
}

export function PrivateRoute({ children }: any) {
  let auth = useUserIdentity();
  if (auth.userRoles !== null) {
    return <Navigate to="/dashboard/calendar" replace />;
  }
  return children;
}

export function GuestRoute({ children }: any) {
  useEffect(() => {
    localStorageService.clearWithoutExclusion(["calendarZoomScale"]);
  }, []);

  return children;
}
