import { IAuthDataToLocalStorageProps } from "services/types/localStorageTypes";
import { getValueFromLocalStorage, setValueToLocalStorage } from "utils/localStorage";

class LocalStorageService {
  removeDataFromLocalStorage(item: string): void {
    localStorage.removeItem(item);
  };

  clearWithoutExclusion(items: string[]): void {
    const exclusionValues = [];
    for (let i = 0; i <= localStorage.length; i++) {
      const key = localStorage.key(i);
      key && items.includes(key) &&
        exclusionValues.push({
          field: key,
          value: getValueFromLocalStorage(key) || ""
        })
    }

    localStorage.clear();

    exclusionValues.forEach((item) => {
      setValueToLocalStorage({
        field: item.field,
        value: item.value
      });
    })
  }

  setAuthDataToLocalStorage(arg: IAuthDataToLocalStorageProps): void {
    setValueToLocalStorage({
      field: "userToken",
      value: arg.userToken
    });
    setValueToLocalStorage({
      field: "refreshToken",
      value: arg.refreshToken
    });
    setValueToLocalStorage({
      field: "refreshTokenExpire",
      value: arg.refreshTokenExpire
    });
  }
}

export default new LocalStorageService();