import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ISnackbar } from "redux/types/snackbar";

// TODO: move to searchbar slice
const initialState: ISnackbar = {
  open: false,
  text: "",
  variant: "info",
};

export const snackbarSlice = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    snackbar: (state, action: PayloadAction<ISnackbar>) => {
      state.open = action.payload.open;
      state.text = action.payload.text;
      state.variant = action.payload.variant;
    },
  },
});

export const { snackbar } = snackbarSlice.actions;
export default snackbarSlice.reducer;
