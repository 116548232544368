import { Paper } from "@mui/material";
import { styled } from "@mui/system";

const AppCenteredCardBorder = styled(
  Paper,
  {}
)(({ theme }) => ({
  padding: theme.spacing(3),
  justifyContent: "center",
  bottom: 10,
  left: 10,
  width: 450,
  borderRadius: 10,
}));

AppCenteredCardBorder.defaultProps = {
  elevation: 4,
};

export default AppCenteredCardBorder;
