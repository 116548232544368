import axios from "axios";
import { loginSuccess } from "redux/reducers/auth";
import { store } from "redux/store";
import errorSnackbar from "./errorSnackbar";
import localStorageService from "services/localStorage/localStorage.service";

let baseURL = process.env.REACT_APP_BASE_URL;
let SERVER_URL = process.env.REACT_APP_BASE_URL;
let instance = axios.create();
instance.defaults.baseURL = baseURL + "api/v1";
instance.interceptors.request.use(
  (config: any) => {
    const userToken = localStorage.getItem("userToken");
    let headers = window.localStorage.getItem("databaseId");
    if (userToken) {
      config.headers.Authorization = "Bearer " + userToken;
    }
    if (headers) {
      config.headers.databaseId = headers;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (request) => request,
  (err) => {
    if (err.message === "Network Error") {
      errorSnackbar("Wystąpił problem z siecią");
    }
    return new Promise((resolve, reject) => {
      const originalReq = err.config;
      if (
        err.response.status === 401 &&
        err.config &&
        !err.config.__isRetryRequest
      ) {
        originalReq._retry = true;
        let res = fetch(SERVER_URL + "api/v1/auth/refresh-token", {
          method: "POST",
          mode: "cors",
          cache: "no-cache",
          headers: {
            "Content-Type": "application/json",
            Device: "device",
          },
          credentials: "same-origin",
          redirect: "follow",
          referrer: "no-referrer",
          body: JSON.stringify({
            Token: localStorage.getItem("refreshToken"),
          }),
        })
          .then((res) => res.json())
          .then((res) => {
            if (res.noToken) {
              localStorage.clear();
              window.location.reload();
            } else {
              localStorageService.setAuthDataToLocalStorage({
                userToken: res.token,
                refreshToken: res.refreshToken,
                refreshTokenExpire: res.refreshTokenExpire.toString()
              });
              store.dispatch(
                loginSuccess({
                  isLoggedIn: true,
                  userToken: res.token,
                  refreshToken: res.refreshToken,
                })
              );
              originalReq.headers["Authorization"] = "Bearer " + res.token;
              return axios(originalReq);
            }
          });
        resolve(res);
      } else {
        switch (true) {
          case (!!err.response.data[0]):
            errorSnackbar(err.response.data[0]);
            break;
          case (!!err.response.data.errors[0]):
            errorSnackbar(err.response.data.errors[0]);
            break;
          default:
            errorSnackbar();
            break;
        }
      }
      reject(err);
    });
  }
);

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default instance;
