import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import {
	IAuthenticationState
} from '../types/auth';

const userToken = localStorage.getItem('userToken');
const refreshToken = localStorage.getItem('refreshToken');
const initialState: IAuthenticationState = userToken
	? { isLoggedIn: true, userToken, refreshToken: refreshToken }
	: { isLoggedIn: false, userToken: null, refreshToken: null };

export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		loginSuccess: (state, action: PayloadAction<IAuthenticationState>) => {
			state.isLoggedIn = action.payload.isLoggedIn;
			state.userToken = action.payload.userToken;
			state.refreshToken = action.payload.refreshToken;
		},
		loginFail: (state) => {
			state.isLoggedIn = false;
			state.userToken = null;
			state.refreshToken = null;
		},
		logoutSuccess: (state) => {
			state.isLoggedIn = false;
			state.userToken = null;
			state.refreshToken = null;
		},
	},
});

export const { loginSuccess, loginFail, logoutSuccess } = authSlice.actions;
export const selectIsLogged = (state: RootState) => state.auth.isLoggedIn;
export const selectToken = (state: RootState) => state.auth.userToken;
export default authSlice.reducer;