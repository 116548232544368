import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// TODO: move to calendar slice
export type CalendarTypesOfView = "doctor" | "seat";

const initialState: { calendarTypesOfView: CalendarTypesOfView } = {
  calendarTypesOfView: "doctor",
};

export const calendarDisplayedViewSlice = createSlice({
  name: "calendarDisplayedView",
  initialState,
  reducers: {
    setCalendarDisplayedView: (
      state,
      action: PayloadAction<CalendarTypesOfView>
    ) => {
      state.calendarTypesOfView = action.payload;
    },
  },
});

export const { setCalendarDisplayedView } = calendarDisplayedViewSlice.actions;
export default calendarDisplayedViewSlice.reducer;
