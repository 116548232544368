import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IModalButton } from "redux/types/modalButton";

const initialState: IModalButton = {
  addPatient: false,
  addVisit: false,
  editVisit: false,
  addNote: false,
  isEditNoteModal: false,
  vacationPlanner: false,
  vacationFormModal: false,
  vacationEditFormModal: false,
  nextAvailable: false,
  sendMessage: false,
  removeVisitDialog: false,
};

export const modalButtonSlice = createSlice({
  name: "modalButton",
  initialState,
  reducers: {
    openModal: (state, action: PayloadAction<IModalButton>) => {
      state.addPatient = action.payload.addPatient;
      state.addVisit = action.payload.addVisit;
      state.editVisit = action.payload.editVisit;
      state.addNote = action.payload.addNote;
      state.nextAvailable = action.payload.nextAvailable;
      state.isEditNoteModal = action.payload.isEditNoteModal;
      state.vacationPlanner = action.payload.vacationPlanner;
      state.vacationFormModal = action.payload.vacationFormModal;
      state.vacationEditFormModal = action.payload.vacationEditFormModal;
      state.sendMessage = action.payload.sendMessage;
      state.removeVisitDialog = action.payload.removeVisitDialog;
      state.messageReport = action.payload.messageReport;
    },
  },
});

export const { openModal } = modalButtonSlice.actions;
export default modalButtonSlice.reducer;
