import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getValueFromLocalStorage } from "utils/localStorage";

// TODO: move to calendar slice
export interface ICalendarScroll {
  scrollPosition: number | null;
  lastCalendarView: "doctor" | "seat";
}

const initialState: ICalendarScroll = {
  scrollPosition: null,
  lastCalendarView: "doctor"
};

export const createCalendarScrollSlice = createSlice({
  name: "calendarScroll",
  initialState,
  reducers: {
    setScrollPosition: (state, action: PayloadAction<number | null>) => {
      const lastView = getValueFromLocalStorage("lastView");
      if (lastView !== "dayGridMonth") {
        state.scrollPosition = action.payload;
      }
    },
    setLastCalendarView: (state, action: PayloadAction<"doctor" | "seat">) => {
      state.lastCalendarView = action.payload;
    }
  },
});

export const { setScrollPosition, setLastCalendarView } = createCalendarScrollSlice.actions;
export default createCalendarScrollSlice.reducer;
