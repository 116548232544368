export const NOTES_DOCTOR_ID = null;
export const CLINIC_CLOSED_DOCTOR_ID = null;
export const ALL_DAY_EVENTS_CREATED_BY_DOCTOR_ID = null;
export const NEXT_AVAILABLE_APPOINTMENT_TIME = 0;
export const calendarZoomSizes = [5, 10, 15, 20, 30];
export const nextAvailableTermOptions = [2, 4, 6, 8];
export const nextAvailableTermDefault = 6;
export const defaultFontSizeFormHeaderDesktop = 32;
export const defaultFontSizeFormHeaderMobile = 24;

// set this value if you want reset local storage in client browser
export const minimalAcceptedAppVersion = "1.0.0";
