import { store } from "redux/store";
import { snackbar } from "redux/reducers/snackbar";

export default function errorSnackbar(text?: string) {
  store.dispatch(
    snackbar({
      open: true,
      text: `${text !== undefined ? text : "Błąd komunikacji z serwerem"}`,
      variant: "error",
    })
  );
}
