import { Paper } from "@mui/material";
import { styled } from "@mui/system";

const Footer = styled(
  Paper,
  {}
)(({ theme }) => ({
  padding: theme.spacing(3),
  position: "fixed",
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  bottom: 0,
  left: 0,
  width: "100%",
}));

Footer.defaultProps = {
  elevation: 3,
};

export default Footer;
