import { Typography } from "@mui/material";

interface IAppPageTitleProps {
  text: string;
  mt?: number;
}

export default function AppPageTitle(props: IAppPageTitleProps) {
  const { text, mt } = props;
  return (
    <Typography
      style={{
        marginTop: mt !== undefined ? mt : 15,
        fontSize: "1rem",
      }}
      variant="subtitle2"
      component="h3"
    >
      {text}
    </Typography>
  );
}
