import { ReactChild } from "react";
import { Container, Typography } from "@mui/material";
// import ChangeThemeButton from "./parts/ChangeThemeButton";
import Footer from "../../layouts/auth/parts/Footer";

interface IAuthLayoutProps {
  children: ReactChild;
}

export default function AuthLayout(props: IAuthLayoutProps) {
  const { children } = props;

  return (
    <Container maxWidth={false} sx={{ pt: 6 }}>
      {/* <ChangeThemeButton /> */}

      {children}
      <Footer>
        <Typography variant="body1">
          © Tukano Software House Sp. z o. o.
        </Typography>
      </Footer>
    </Container>
  );
}
