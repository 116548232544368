import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICalendarSettings } from "redux/types/calendarSettings";
import { calendarZoomSizes } from "utils/consts";
import { getValueFromLocalStorage, setValueToLocalStorage } from "utils/localStorage";

// TODO: move to calendar slice
const initialState: ICalendarSettings = {
  calendarScale: Number(getValueFromLocalStorage("calendarZoomScale")) || undefined,
  isZoomPanelVisible: getValueFromLocalStorage("lastView") === "dayGridMonth" ? false : true,
  zoomType: undefined
}

export const calendarSettingsSlice = createSlice({
  name: "calendarSettings",
  initialState,
  reducers: {
    setCalendarSettings: (state, action: PayloadAction<ICalendarSettings>) => {
      if (!state.calendarScale) {
        state.calendarScale = calendarZoomSizes[0];
      }
      else {
        const index = calendarZoomSizes.indexOf(state.calendarScale);
        action.payload?.zoomType === "zoomIn" && index > 0 && (state.calendarScale = calendarZoomSizes[index - 1])
        action.payload?.zoomType === "zoomOut" && index < calendarZoomSizes.length - 1 && (state.calendarScale = calendarZoomSizes[index + 1])
      }
      setValueToLocalStorage({
        field: "calendarZoomScale",
        value: state.calendarScale.toString()
      });
    },
    setZoomPanelVisibility: (state, action: PayloadAction<ICalendarSettings>) => {
      state.isZoomPanelVisible = action.payload.isZoomPanelVisible;
    }
  },
});

export const { setCalendarSettings, setZoomPanelVisibility } = calendarSettingsSlice.actions;
export default calendarSettingsSlice.reducer;
