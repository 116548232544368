import { Typography } from "@mui/material";

interface IAppTitleNormalProps {
  text: string;
}

export default function AppTitleNormal(props: IAppTitleNormalProps) {
  const { text } = props;
  return (
    <Typography
      style={{
        marginTop: 15,
        marginBottom: -5,
        fontSize: "1rem",
      }}
      variant="subtitle1"
      component="h1"
    >
      {text}
    </Typography>
  );
}
