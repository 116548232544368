import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// TODO: move to calendar slice
const initialState: { unselect: boolean } = {
  unselect: false,
};

export const unselectCalendar = createSlice({
  name: "unselectCalendar",
  initialState,
  reducers: {
    setUnselectCalendar: (state, action: PayloadAction<boolean>) => {
      state.unselect = action.payload;
    },
  },
});

export const { setUnselectCalendar } = unselectCalendar.actions;
export default unselectCalendar.reducer;
