import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IRefetchCalendarEvents } from "redux/types/userActions";

// TODO: move to calendar slice
const initialState: IRefetchCalendarEvents = {
  refetch: false,
  displayLoader: true,
};

export const refetchCalendarEvents = createSlice({
  name: "refetchCalendarEvents",
  initialState,
  reducers: {
    setRefetchCalendarEvents: (
      state,
      action: PayloadAction<IRefetchCalendarEvents>
    ) => {
      state.refetch = action.payload.refetch;
      state.displayLoader = action.payload.displayLoader;
    },
  },
});

export const { setRefetchCalendarEvents } = refetchCalendarEvents.actions;
export default refetchCalendarEvents.reducer;
