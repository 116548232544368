import { styled } from "@mui/system";

const AppFormSegment = styled(
  "div",
  {}
)(({ theme }) => ({
  display: "block",
  marginTop: 10,
  marginBottom: theme.spacing(1),
}));

export default AppFormSegment;
