import { styled } from "@mui/system";

const AppCenteredHeader = styled(
  "div",
  {}
)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
}));

export default AppCenteredHeader;
