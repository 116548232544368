import { useCallback } from "react"
import { useAppDispatch } from "redux/hooks";
import { snackbar } from "redux/reducers/snackbar";

export default function SnackbarDispatch() {
  const dispatch = useAppDispatch();

  const displaySuccessText = useCallback((text: string) => {
    dispatch(
      snackbar({
        open: true,
        text: text,
        variant: "success",
      })
    );
  }, [dispatch]);

  const displayInfoText = useCallback((text: string) => {
    dispatch(
      snackbar({
        open: true,
        text: text,
        variant: "info",
      })
    );
  }, [dispatch]);

  return {
    displaySuccessText,
    displayInfoText
  }
}