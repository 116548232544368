import { createTheme } from "@mui/material/styles";
import { darkGreen } from "./customColors";

export const generateModeTheme = (mode: "light" | "dark") =>
  createTheme({
    palette: {
      mode,
      primary: {
        main: darkGreen,
      },
    },
  });
