import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ICalendar } from "redux/types/calendar";

const initialState: ICalendar = {
  selectedDoctorId: null,
  selectedSeatNumber: null,
  nextAvailableInstitution: null,
  nextAvailableDoctor: null,
  isSelectedAnyItem: true
}

export const calendarSlice = createSlice({
  name: "calendar",
  initialState,
  reducers: {
    setSelectedDoctorId: (state, action: PayloadAction<string>) => {
      state.selectedDoctorId = action.payload;
    },
    setSelectedSeatNumber: (state, action: PayloadAction<number>) => {
      state.selectedSeatNumber = action.payload;
    },
    setNextAvailableInstitution: (state, action: PayloadAction<string | null>) => {
      state.nextAvailableInstitution = action.payload;
    },
    setNextAvailableDoctor: (state, action: PayloadAction<string | "all" | null>) => {
      state.nextAvailableDoctor = action.payload;
    },
    setIsSelectedAnyDoctor: (state, action: PayloadAction<boolean>) => {
      state.isSelectedAnyItem = action.payload;
    }
  }
});

export const {
  setSelectedDoctorId,
  setSelectedSeatNumber,
  setNextAvailableInstitution,
  setNextAvailableDoctor,
  setIsSelectedAnyDoctor
} = calendarSlice.actions;
export default calendarSlice.reducer;