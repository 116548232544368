import { AppLogo, AppSubmitButton } from "components/common";
import AppBackButton from "components/common/AppBackButton";
import { AppForm, AppPasswordInput } from "components/forms";
import {
  AppCenteredCard,
  AppCenteredHeader,
  AppPageTitle,
} from "components/layouts";
import AuthLayout from "layouts/auth/AuthLayout";
import useConfirmResetPasswordForm from "./resetPasswordPage/useConfirmResetPasswordForm";
import { DefaultTheme } from "styled-components";

export default function ConfirmResetPasswordPage() {
  const { control, onSubmit, changePending } = useConfirmResetPasswordForm();

  return (
    <AuthLayout>
      <AppCenteredCard>
        <AppBackButton to="/" text="Przejdź do logowania" />
        <AppCenteredHeader>
          <AppLogo />
        </AppCenteredHeader>
        <AppPageTitle
          text="Aby zmienić hasło w aplikacji Ortodoncja Cloud, wprowadź poniżej
            nowe hasło"
        />
        <AppForm>
          <AppPageTitle text="Hasło" />
          <AppPasswordInput
            control={control}
            name="password"
            label="password*"
          />
          <AppPageTitle text="Powtórz hasło" />
          <AppPasswordInput
            control={control}
            name="repeatPassword"
            label="repeatPassword*"
          />
          <AppCenteredHeader>
            <AppSubmitButton
              loading={changePending}
              disabled={changePending}
              onClick={onSubmit}
              sx={AppSubmitButtonStyles}
            >
              Zmień hasło
            </AppSubmitButton>
          </AppCenteredHeader>
        </AppForm>
      </AppCenteredCard>
    </AuthLayout>
  );
}

const AppSubmitButtonStyles: DefaultTheme = {
  px: 3,
  py: 1,
  mt: 2,
  width: "100%",
};
