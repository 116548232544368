import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SeatOptions } from "redux/types/seatOptions";

// TODO: move to calendar slice
// TODO: name variables - noun
// TODO: name function - verb
const initialState: SeatOptions = {
  refresh: false,
  databaseList: [],
};

export const getSeatsOptions = createSlice({
  name: "getSeatsOptions",
  initialState,
  reducers: {
    refreshSeatsOptions: (state, action: PayloadAction<SeatOptions>) => {
      state.refresh = action.payload.refresh;
      state.databaseList = action.payload.databaseList;
    },
  },
});

export const { refreshSeatsOptions } = getSeatsOptions.actions;
export default getSeatsOptions.reducer;
