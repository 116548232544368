import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IEditVisit } from "redux/types/editVisit";

const initialState: IEditVisit = {
  editMode: false,
  databaseId: "",
  data: {
    name: "",
    id: -1,
    notes: "",
    doctorId: "",
    eventTypeKey: "",
    appMode: -1,
    patientId: -1,
    seat: -1,
    time: "",
    selectedDateTimestamp: 0,
    selectedHourTimestamp: 0,
  },
};

export const editVisitSlice = createSlice({
  name: "editVisit",
  initialState,
  reducers: {
    editVisitData: (state, action: PayloadAction<IEditVisit>) => {
      state.editMode = action.payload.editMode;
      state.databaseId = action.payload.databaseId;
      state.data = action.payload.data;
    },
  },
});

export const { editVisitData } = editVisitSlice.actions;
export default editVisitSlice.reducer;
