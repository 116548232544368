import React, { useEffect, useState } from "react";
import { ThemeProvider } from "@mui/material/styles";
import { generateModeTheme } from "./mui/theme";
import { CssBaseline } from "@mui/material";
import "react-perfect-scrollbar/dist/css/styles.css";
import AppRouter from "./AppRouter";
import localstorageService from "services/localStorage/localStorage.service";
import useVersion from "hooks/use-version";
import AppGlobalError from "components/layouts/AppGlobalError";

export const ColorModeContext = React.createContext({
  toggleColorMode: () => {},
});

function App() {
  const [mode, setMode] = useState<"light" | "dark">("light");
  const { checkAppVersion } = useVersion();

  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    []
  );

  useEffect(() => {
    checkAppVersion();
  }, [checkAppVersion]);

  const theme = React.useMemo(() => {
    return generateModeTheme(mode);
  }, [mode]);

  localstorageService.removeDataFromLocalStorage("dateInCalendarView");

  return (
    <>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <AppGlobalError>
            <AppRouter />
          </AppGlobalError>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </>
  );
}

export default App;
