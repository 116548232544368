import { AppLink } from "components/common";
import { AppFormSegment } from "components/forms";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Box, SxProps } from "@mui/material";

const iconArrowStyles = {
  fontSize: 14,
  position: "relative",
  top: 1,
};

interface IAppBackButton {
  to: string;
  text: string;
  sx?: SxProps;
}

export default function AppBackButton(props: IAppBackButton) {
  const { to, text, sx } = props;
  return (
    <AppFormSegment>
      <AppLink to={to}>
        <Box sx={sx}>
          <ArrowBackIosIcon sx={iconArrowStyles} />
          {text}
        </Box>
      </AppLink>
    </AppFormSegment>
  );
}
