import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// TODO: move to modals
const initialState: { open: boolean } = {
  open: false,
};

export const changePasswordModalSlice = createSlice({
  name: "changePasswordModal",
  initialState,
  reducers: {
    setChangePasswordModal: (state, action: PayloadAction<boolean>) => {
      state.open = action.payload;
    },
  },
});

export const { setChangePasswordModal } = changePasswordModalSlice.actions;
export default changePasswordModalSlice.reducer;
