import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import authReducer from "./reducers/auth";
import modalButton from "./reducers/modalButton";
import userActions from "./reducers/userActions";
import userInformationReducer from "./reducers/userInformation";
import clearSearchbar from "redux/reducers/clearSearchbar";
import calendarSelected, { CalendarTypes } from "./reducers/calendarSelected";
import refetchCalendarEvents from "./reducers/refetchCalendarEvents";
import calendarDisplayedView, {
  CalendarTypesOfView,
} from "./reducers/calendarDisplayedView";
import snackbar from "./reducers/snackbar";
import editVisit from "./reducers/editVisit";
import calendarHandleDate, {
  ICalendarDate,
} from "./reducers/calendarHandleDate";
import eventInfo from "./reducers/eventInfo";
import visitValidator from "./reducers/visitValidator";
import unselectCalendar from "./reducers/unselectCalendar";
import createVisit from "./reducers/createVisit";
import changePasswordModal from "./reducers/changePasswordModal";
import getSeatsOptions from "./reducers/getSeatsOptions";
import vacationPlanner from "./reducers/vacationPlanner";
import { IAuthenticationState } from "./types/auth";
import { IUserInformation } from "./types/userInformation";
import { IModalButton } from "./types/modalButton";
import { IRefetchCalendarEvents, IUserActions } from "./types/userActions";
import { ISnackbar } from "./types/snackbar";
import { IEditVisit } from "./types/editVisit";
import { IEventInfo } from "./types/eventInfo";
import { IVisitValidator } from "./types/visitValidator";
import { ICreateVisit } from "./types/createVisit";
import { IVacationPlanner } from "./types/vacationPlanner";
import { SeatOptions } from "./types/seatOptions";
import calendarScroll, { ICalendarScroll } from "./reducers/calendarScroll";
import { ICalendarSettings } from "./types/calendarSettings";
import calendarSettings from "./reducers/calendarSettings";
import { ICalendar } from "./types/calendar";
import calendar from "./reducers/calendar";

export interface IStore {
  auth: IAuthenticationState;
  userInformation: IUserInformation;
  modalButton: IModalButton;
  userActions: IUserActions;
  clearSearchbar: { clear: boolean };
  calendarSelected: { calendarType: CalendarTypes };
  refetchCalendarEvents: IRefetchCalendarEvents;
  calendarDisplayedView: { calendarTypesOfView: CalendarTypesOfView };
  snackbar: ISnackbar;
  editVisit: IEditVisit;
  calendarHandleDate: ICalendarDate;
  eventInfo: IEventInfo;
  visitValidator: IVisitValidator;
  unselectCalendar: { unselect: boolean };
  createVisit: ICreateVisit;
  changePasswordModal: { open: boolean };
  getSeatsOptions: SeatOptions;
  vacationPlanner: IVacationPlanner;
  calendarScroll: ICalendarScroll;
  calendarSettings: ICalendarSettings;
  calendar: ICalendar;
}

export const store = configureStore<IStore>({
  reducer: {
    auth: authReducer,
    userInformation: userInformationReducer,
    // TODO: change name for modals
    modalButton: modalButton,
    userActions: userActions,
    clearSearchbar: clearSearchbar,
    calendarSelected: calendarSelected,
    refetchCalendarEvents: refetchCalendarEvents,
    calendarDisplayedView: calendarDisplayedView,
    snackbar: snackbar,
    editVisit: editVisit,
    calendarHandleDate: calendarHandleDate,
    eventInfo: eventInfo,
    visitValidator: visitValidator,
    unselectCalendar: unselectCalendar,
    createVisit: createVisit,
    changePasswordModal: changePasswordModal,
    getSeatsOptions: getSeatsOptions,
    vacationPlanner: vacationPlanner,
    calendarScroll: calendarScroll,
    calendarSettings: calendarSettings,
    calendar: calendar
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
