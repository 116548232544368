import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUserActions } from "redux/types/userActions";

const initialState: IUserActions = {
  // TODO: move to modals slice
  forwardToAddPatient: false,
  isRecentlyClosedAddPatientModal: false,
  data: [{ id: "", name: "", surname: "" }],
  // TODO: move to calendars slice
  contextMenu: null,
};

export const userActionsSlice = createSlice({
  name: "userActions",
  initialState,
  reducers: {
    forwardInto: (state, action: PayloadAction<IUserActions>) => {
      state.forwardToAddPatient = action.payload.forwardToAddPatient;
      state.data = action.payload.data;
    },
    eventContextMenu: (state, action: PayloadAction<IUserActions>) => {
      state.contextMenu = action.payload.contextMenu;
    },
    setIsRecentlyClosedAddPatientModal: (state, action: PayloadAction<boolean>) => {
      state.isRecentlyClosedAddPatientModal = action.payload;
    }
  },
});

export const {
  forwardInto,
  eventContextMenu,
  setIsRecentlyClosedAddPatientModal
} = userActionsSlice.actions;
export default userActionsSlice.reducer;
