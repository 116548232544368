import React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "redux/hooks";
import { snackbar } from "redux/reducers/snackbar";
import { SnackbarProvider, useSnackbar } from "notistack";

function Snackbars() {
  const snackbarData = useSelector((state: any) => state.snackbar);
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (snackbarData.open) {
      enqueueSnackbar(snackbarData.text, { variant: snackbarData.variant });
      dispatch(
        snackbar({
          open: false,
          text: "",
          variant: "info",
        })
      );
    }
  }, [dispatch, enqueueSnackbar, snackbarData]);

  return <></>;
}

export default function SnackbarHandler() {
  return (
    <SnackbarProvider maxSnack={10}>
      <Snackbars />
    </SnackbarProvider>
  );
}
