import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICreateVisit } from "redux/types/createVisit";

const initialState: ICreateVisit = {
  createMode: false,
  databaseId: "",
  data: {
    doctorId: "",
    time: "",
    seat: "",
    selectedDateTimestamp: 0,
    selectedHourTimestamp: 0,
  },
};

export const creteVisitSlice = createSlice({
  name: "eventInfo",
  initialState,
  reducers: {
    createVisitData: (state, action: PayloadAction<ICreateVisit>) => {
      state.createMode = action.payload.createMode;
      state.databaseId = action.payload.databaseId;
      state.data = action.payload.data;
      state.isFromDragEvent = action.payload.isFromDragEvent;
    },
  },
});

export const { createVisitData } = creteVisitSlice.actions;
export default creteVisitSlice.reducer;
