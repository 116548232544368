import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IVacationPlanner } from "redux/types/vacationPlanner";

const initialState: IVacationPlanner = {
  data: {
    startDate: "",
    endDate: "",
  },
  editData: {
    id: 0,
    doctorId: "",
    isVacation: false,
    notes: "",
    databaseId: "",
  },
};

export const vacationPlannerSlice = createSlice({
  name: "vacationPlanner",
  initialState,
  reducers: {
    vacationPlannerData: (state, action: PayloadAction<IVacationPlanner>) => {
      state.data = action.payload.data;
    },
    vacationEditData: (state, action: PayloadAction<IVacationPlanner>) => {
      state.data = action.payload.data;
      state.editData = action.payload.editData;
    },
    noteEditData: (state, action: PayloadAction<IVacationPlanner>) => {
      state.data = action.payload.data;
      state.editData = action.payload.editData;
    },
  },
});

export const { vacationPlannerData, vacationEditData, noteEditData } =
  vacationPlannerSlice.actions;
export default vacationPlannerSlice.reducer;
