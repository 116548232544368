import Box from "@mui/material/Box";
import logo from "../../assets/images/logo120x120.png";

export default function AppLogo() {
  return (
    <Box
      component="img"
      sx={{
        maxWidth: { xs: 120, md: 180 },
        mb: 1,
      }}
      alt="Application logo"
      src={logo}
    />
  );
}
