import handleErrorMessage from "utils/handleErrorMessage";
import {
  IAuthentication,
  IConfirmAccount,
  ILoginResponse,
  IPassword,
  IRefreshTokenResponse,
} from "../redux/types/auth";
import axiosInstance from "../utils/axios";
import { clearLocalStorage } from "utils/localStorage";

class AuthService {
  passwordReset(PasswordData: IPassword) {
    return axiosInstance
      .post("/auth/reset-password", {
        email: PasswordData.email,
      })
      .then((response) => {
        return response;
      });
  }

  login(loginData: IAuthentication) {
    const { email, password, token } = loginData;
    return axiosInstance
      .post("/auth/login", {
        email,
        password,
        token,
      })
      .then((response) => {
        return Promise.resolve<ILoginResponse>(response.data);
      });
  }

  logout() {
    clearLocalStorage();
  }

  confirmAccount(accountData: IConfirmAccount) {
    return axiosInstance
      .post("/auth/confirm-account", {
        userId: accountData.userId,
        token: accountData.token,
        password: accountData.password,
      })
      .then((response) => {
        return response;
      })
      .catch((e) => {
        handleErrorMessage(e);
        return Promise.reject(e);
      });
  }

  refreshToken(refreshToken: string) {
    return axiosInstance
      .post("/auth/refresh-token", {
        token: refreshToken
      })
      .then((response) => {
        return Promise.resolve<IRefreshTokenResponse>(response.data);
      })
      .catch((e) => {
        handleErrorMessage(e);
        return Promise.reject(e);
      });
  }
}

export default new AuthService();
