import { useCallback } from "react";
import { gt, major } from "semver";
import packageJson from "../../package.json";
import { clearLocalStorage, getValueFromLocalStorage, setValueToLocalStorage } from "utils/localStorage";
import { minimalAcceptedAppVersion } from "utils/consts";

export default function useVersion() {
  const checkAppVersion = useCallback(() => {
    const savedVersion = getValueFromLocalStorage("app-version")

    if (!!!savedVersion) {
      setValueToLocalStorage({
        field: "app-version",
        value: packageJson.version
      });

      return;
    }

    if (major(packageJson.version) !== major(savedVersion)
      || gt(minimalAcceptedAppVersion, savedVersion)) {
      clearLocalStorage();
      window.location.reload();
    }
  }, []);

  return {
    checkAppVersion
  }
}