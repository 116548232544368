import LoadingButton from "@mui/lab/LoadingButton";
import { styled } from "@mui/system";
import { darkGreen } from "mui/customColors";

const AppSubmitButton = styled(
  LoadingButton,
  {}
)(({ theme }) => ({
  backgroundColor: darkGreen,
  height: 45,
  marginLeft: 0,
  "&:hover": {
    backgroundColor: darkGreen,
  },
}));

AppSubmitButton.defaultProps = {
  variant: "contained",
  type: "submit",
  property: "submit",
};

export default AppSubmitButton;
