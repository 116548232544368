import { Link, Link as RouterLink } from "react-router-dom";
import { styled } from "@mui/system";
import { ReactNode } from "react";
import { darkGreen } from "mui/customColors";

interface IAppLinkProps {
  to: string;
  children: ReactNode | string | null;
}

const Mylink = styled(
  Link,
  {}
)(({ theme }) => ({
  color: darkGreen,
  marginTop: 10,
  textDecoration: "none",
  component: { RouterLink },
}));

export default function AppLink(props: IAppLinkProps) {
  const { to, children } = props;
  return (
    <Mylink
      sx={{
        textDecoration: "none",
      }}
      to={to}
    >
      {children}
    </Mylink>
  );
}
