import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IVisitValidator } from "redux/types/visitValidator";

const initialState: IVisitValidator = {
  isInDoctorWorkHours: false,
  hasDoctorAnotherVisitDuringTime: false,
  hasPatientVisitOnDay: false,
  isSeatFree: true,
  hasDoctorAnotherVisitAtAnotherPlaceDuringTime: false,
  forwardAdd: false,
  forwardEdit: false,
  cancel: true,
  changeDuration: true,
  isDurationPossible: true,
};

export const visitValidatorSlice = createSlice({
  name: "visitValidator",
  initialState,
  reducers: {
    validateVisit: (state, action: PayloadAction<IVisitValidator>) => {
      state.isInDoctorWorkHours = action.payload.isInDoctorWorkHours;
      state.hasDoctorAnotherVisitDuringTime =
        action.payload.hasDoctorAnotherVisitDuringTime;
      state.hasPatientVisitOnDay = action.payload.hasPatientVisitOnDay;
      state.isSeatFree = action.payload.isSeatFree;
      state.hasDoctorAnotherVisitAtAnotherPlaceDuringTime = action.payload.hasDoctorAnotherVisitAtAnotherPlaceDuringTime;
      state.forwardAdd = action.payload.forwardAdd;
      state.forwardEdit = action.payload.forwardEdit;
      state.cancel = action.payload.cancel;
      state.changeDuration = action.payload.changeDuration;
      state.isDurationPossible = action.payload.isDurationPossible;
    },
  },
});

export const { validateVisit } = visitValidatorSlice.actions;
export default visitValidatorSlice.reducer;
