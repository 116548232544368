import localStorageService from "services/localStorage/localStorage.service";

interface ILocalStorageProps {
  field: string;
  value: string;
}

export function setValueToLocalStorage(data: ILocalStorageProps) {
  window.localStorage.setItem(data.field, data.value);
}

export function getValueFromLocalStorage(data: string) {
  return localStorage.getItem(data);
}

export function removeValueFromLocalStorage(data: string) {
  return localStorage.removeItem(data);
}

export function clearLocalStorage() {
  localStorageService.clearWithoutExclusion(["calendarZoomScale"]);
}

export function reloadWhenGuest() {
  const userToken = getValueFromLocalStorage("userToken");
  if (userToken === null) {
    clearLocalStorage();
    window.location.reload();
  }
}