import * as yup from "yup";
import yupPassword from "yup-password";

yupPassword(yup);
export const newPasswordValidationSchema = yup.object().shape({
  password: yup
    .string()
    .minLowercase(
      1,
      "Hasło musi zawierać małą literę, dużą literę, cyfrę i znak specjalny"
    )
    .minUppercase(
      1,
      "Hasło musi zawierać małą literę, dużą literę, cyfrę i znak specjalny"
    )
    .minNumbers(
      1,
      "Hasło musi zawierać małą literę, dużą literę, cyfrę i znak specjalny"
    )
    .minSymbols(
      1,
      "Hasło musi zawierać małą literę, dużą literę, cyfrę i znak specjalny"
    )
    .required()
    .min(6, "Hasło musi zawierać minimum 6 znaków")
    .max(15, "Hasło może zawierać maksymalnie 15 znaków"),
  repeatPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Hasła nie są takie same")
    .minLowercase(
      1,
      "Hasło musi zawierać małą literę, dużą literę, cyfrę i znak specjalny"
    )
    .minUppercase(
      1,
      "Hasło musi zawierać małą literę, dużą literę, cyfrę i znak specjalny"
    )
    .minNumbers(
      1,
      "Hasło musi zawierać małą literę, dużą literę, cyfrę i znak specjalny"
    )
    .minSymbols(
      1,
      "Hasło musi zawierać małą literę, dużą literę, cyfrę i znak specjalny"
    )
    .required()
    .min(6, "Hasło musi zawierać minimum 6 znaków")
    .max(15, "Hasło może zawierać maksymalnie 15 znaków"),
});