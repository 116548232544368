import { Box, Link } from "@mui/material";
import React, { ErrorInfo, ReactNode } from "react";

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
}

export default class AppGlobalError extends React.Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  static getDerivedStateFromError(_: Error): State {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // TODO: implement logger
  }

  render() {
    if (this.state.hasError) {
      return (
        <Box sx={{ display: "flex", height: "100vh" }}>
          <Box sx={errorMessageStyles}>
            Coś poszło nie tak... <br />
            Spróbuj odświeżyć stronę lub skontaktuj się z{" "}
            <Link href="https://ortobajt.pl/" target={"_blank"}>
              supportem Ortodoncja
            </Link>
            .
          </Box>
        </Box>
      );
    }

    return this.props.children;
  }
}

const errorMessageStyles = {
  margin: "auto",
  textAlign: "center",
  fontSize: 25,
};
